<template>
  <div>
    {{ trc }}
  </div>
</template>

<script>
import { TRCS } from '../../../constants/app';
export default {
    props: {object: {type: Object, required: true}},
    computed: {
        trc(){
            let t = TRCS.find(item => item.value === this.object.trc)
            if(t) return t.libelle
            return '-'
        }
    }

}
</script>

<style>

</style>